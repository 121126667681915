import React from 'react';
import {
  Box,
  LinkText,
  List,
  ListItem,
  Text,
} from '@mediahuis/chameleon-react-legacy';

export const Language = () => (
  <>
    <Box mb={5}>
      <Text size="Heading6" fontFamily="system" fontWeight="bold">
        The Luxembourg Times Mobile App
      </Text>
    </Box>
    <Box mb={5}>
      <Text size="Heading6" fontFamily="system">
        The Luxembourg Times app provides the latest news along with features
        like “Your Luxembourg,” Sudoku, cinema, events, weather updates, and
        traffic information. Subscribers can access exclusive premium content
        labeled ‘LT+’ by logging in. You can also receive push notifications for
        important news alerts, ensuring you stay updated and informed about the
        latest developments with in-depth background context.{' '}
      </Text>
    </Box>
    <List mb={5}>
      <ListItem>
        <LinkText
          href="https://apps.apple.com/gb/app/luxembourg-times/id1596898229"
          target="_blank"
        >
          To the Apple app store{' '}
        </LinkText>
      </ListItem>
      <ListItem>
        <LinkText
          href="https://play.google.com/store/apps/details?id=lu.wort.main.luxtimes&pcampaignid=web_share"
          target="_blank"
        >
          To the Google Play Store
        </LinkText>
      </ListItem>
    </List>
    <Box mb={5}>
      <Text size="Heading6" fontFamily="system" fontWeight="bold">
        The Luxembourg Times Newsletter
      </Text>
    </Box>
    <Box mb={5}>
      <Text size="Heading6" fontFamily="system">
        Stay informed with our editorial newsletters, delivered to your inbox
        every morning, evening, and weekend.
      </Text>
    </Box>
    <Box mb={5}>
      <Text size="Heading6" fontFamily="system">
        You can subscribe to the Luxembourg Times newsletters here:{' '}
        <LinkText href="https://www.luxtimes.lu/newsletter" target="_blank">
          https://www.luxtimes.lu/newsletter
        </LinkText>
      </Text>
    </Box>
    <Box mb={5}>
      <Text size="Heading6" fontFamily="system" fontWeight="bold">
        How do I change my email address or password?
      </Text>
    </Box>
    <Box mb={5}>
      <Text size="Heading6" fontFamily="system">
        Go to ‘Profile,’ select ‘My Profile,’ and update your details under
        ‘Login Credentials.’ Don’t forget to save your changes.
      </Text>
    </Box>
    <Box mb={5}>
      <Text size="Heading6" fontFamily="system" fontWeight="bold">
        How do I redeem a subscription voucher?
      </Text>
    </Box>
    <Box mb={5}>
      <Text size="Heading6" fontFamily="system">
        If you have a voucher, click the ‘Subscribe’ button on our website, then
        select ‘Redeem Here’ under the subscription prices. This will take you
        to a dedicated landing page where you can enter your voucher code. If
        you’re not registered, complete your subscription process; otherwise,
        log in to finalize.
      </Text>
    </Box>
    <Box mb={5}>
      <Text size="Heading6" fontFamily="system">
        Access the voucher landing page here.
      </Text>
    </Box>
    <Box mb={5}>
      <Text size="Heading6" fontFamily="system">
        <LinkText href="https://www.luxtimes.lu/newsletter" target="_blank">
          https://www.luxtimes.lu/newsletter
        </LinkText>
      </Text>
    </Box>
    <Box mb={5}>
      <Text size="Heading6" fontFamily="system" fontWeight="bold">
        Are you interested in a corporate subscription program?
      </Text>
    </Box>
    <Box mb={5}>
      <Text size="Heading6" fontFamily="system">
        We offer tailored corporate subscription packages to suit your company’s
        needs. Contact our Commercial Department at regie@regie.lu to receive a
        customized offer.
      </Text>
    </Box>
    <Box mb={5}>
      <Text size="Heading6" fontFamily="system">
        If you have further questions, feel free to reach out to our customer
        service team.
      </Text>
    </Box>
    <Box mb={5}>
      <Text size="Heading6" fontFamily="system">
        Our customer service team will be happy to help you.
      </Text>
    </Box>
    <Box mb={5}>
      <Text size="Heading6" fontFamily="system">
        Write us an e-mail at{' '}
        <LinkText href="mailto:support@luxtimes.lu">
          support@luxtimes.lu{' '}
        </LinkText>
        or call us on{' '}
        <LinkText href="tel:(+352) 49 93 93 93">(+352) 49 93 339</LinkText>{' '}
        (Mon-Fri 7:00-17:00).
      </Text>
    </Box>
  </>
);
