import { PAGES } from '~/constants';
import { ts } from '~/services';

export const links = [
  {
    value: ts.t('Navigation.Page.Home'),
    url: '/',
  },
  {
    value: ts.t('Navigation.Page.Subscription'),
    url: PAGES.subscription,
    icon: 'Subscription',
  },
  {
    value: ts.t('Navigation.Page.ReadDigital'),
    url: PAGES.readDigital,
    icon: 'ReadDigital',
  },
];
