export const PAGES = Object.freeze({
  deliveryComplaint: 'delivery-complaint',
  deliveryComplaintHeading: 'Delivery',
  readDigital: 'read-digital',
  subscription: 'my-subscription',
  holiday: 'holiday',
  other: 'andere',
  faq: 'haeufig-gestellte-fragen',
  home: 'home',
});
