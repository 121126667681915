import { config } from '~/services';

export const ssoConfig = {
  ssoEnabled: true,
  local: {
    clientId: 'ie27z6i7g2ra1ld5r6285ov3dgnta47q',
    baseApiUrl: 'https://sp-sso-test.luxtimes.lu',
    logoutUri: `https://sp-sso-test.luxtimes.lu/logoutAll?redirectUri=${config.eloketUrl}`,
  },
  dev: {
    clientId: 'tmye3d40r5tbmhav472vi4d17yjgcz96',
    baseApiUrl: 'https://sp-sso-dev.luxtimes.lu',
    logoutUri: `https://sp-sso-dev.luxtimes.lu/logoutAll?redirectUri=${config.eloketUrl}`,
  },
  test: {
    clientId: 'i02kualu68mekx9biuc54rh1l4vdjloq',
    baseApiUrl: 'https://sp-sso-test.luxtimes.lu',
    logoutUri: `https://sp-sso-test.luxtimes.lu/logoutAll?redirectUri=${config.eloketUrl}`,
  },
  preview: {
    clientId: '1x4mabldhi81lj10gv6x9cwv7ogaxya0',
    baseApiUrl: 'https://sp-sso-test.luxtimes.lu',
    logoutUri: `https://sp-sso-test.luxtimes.lu/logoutAll?redirectUri=${config.eloketUrl}`,
  },
  production: {
    clientId: 'j4v1wxfl3whkcqurx9bwaqv5701ugg9w',
    baseApiUrl: 'https://user.luxtimes.lu',
    logoutUri: `https://user.luxtimes.lu/logoutAll?redirectUri=${config.eloketUrl}`,
  },
  scope: 'openid',
};
